<template>
   <div></div>
</template>
<script setup lang="ts">
import { onMounted } from "vue"
import { useClerk } from "vue-clerk"
import { useRouter } from "vue-router"
import { useProjectStore, useUserStore } from "../stores"

// When a user visits /sign-out, this view loads
// and signs the user out before redirecting them to /signed-out
const clerk = useClerk()
const router = useRouter()
const projectStore = useProjectStore()
const userStore = useUserStore()

onMounted(async () => {
   await clerk.signOut(() => {
      projectStore.$reset()
      userStore.$reset()
      router.push({ name: "SignedOut" })
   })
})
</script>
