<template>
   <div id="graphs-container">
      <QCard class="graph-card">
         <QCardSection class="bg-primary">
            <div class="text-h5 text-white">Performance</div>
         </QCardSection>
         <QCardSection>
            <div id="graph-controls">
               <QCheckbox
                  size="sm"
                  v-model="showOptimizedVsDefaultConfidenceIntervals"
                  :val="showOptimizedVsDefaultConfidenceIntervals"
                  label="Show 99% Confidence Intervals"
               />

               <QBtnDropdown :label="activeFilter.label" outline>
                  <QList>
                     <template
                        v-for="option in topFilterOptions"
                        v-bind:key="option.val"
                     >
                        <QItem
                           clickable
                           v-close-popup
                           @click="onFilterClick(option)"
                        >
                           <QItemSection>
                              <QItemLabel>{{ option.label }}</QItemLabel>
                           </QItemSection>
                        </QItem>
                     </template>
                  </QList>
               </QBtnDropdown>

               <QToggle
                  v-model="showConversionRateImprovement"
                  label="Relative to Default Conversion Rate"
               />
            </div>
            <OptimizedVsDefaultGraph
               v-if="sessionMetricsStore.hasVariants"
               :show-confidence-intervals="
                  showOptimizedVsDefaultConfidenceIntervals
               "
               :data="transformedOptimizedVsDefaultData"
               height="250px"
            />
            <TrafficDistributionGraph
               ref="trafficDistributionGraphRef"
               v-if="
                  sessionMetricsStore.hasVariants &&
                  finalTrafficDistributionChartData
               "
               height="300px"
               :show-legend="activeFilter.val === 'all' ? false : true"
               :chart-data="finalTrafficDistributionChartData"
            />
            <ConversionRateGraph
               v-if="sessionMetricsStore.hasVariants"
               id="conversion-graph"
               class="graph"
               :showConversionRateImprovement="showConversionRateImprovement"
               :conversionRateFilter="activeFilter.val"
               height="250px"
               :show-legend="activeFilter.val === 'all' ? false : true"
               :chart-data="
                  showConversionRateImprovement
                     ? finalCRImprovementData
                     : finalAbsCRData
               "
            ></ConversionRateGraph>
         </QCardSection>
      </QCard>
   </div>
</template>
<script setup lang="ts">
import { computed, ref, VNodeRef, watch } from "vue"
import OptimizedVsDefaultGraph from "../OptimizedVsDefaultGraph.vue"
import TrafficDistributionGraph from "./TrafficDistributionGraph.vue"
import ConversionRateGraph from "./conversion-rate-graph/ConversionRateGraph.vue"
import { useDashboardStore, useSessionMetricsStore } from "../../stores"
import { FilterOption } from "../../types/graphs"
import {
   createConversionRateByCombinationDatasets,
   createVariantLiftDatasets,
   createVariationServeRateDatasets,
} from "@utils/chartDatasetGenerators"
import { ChartDataset, Point } from "chart.js"
import { getApexColor } from "@utils/chartColors"
import { logInfo } from "@utils/logging"

const sessionMetricsStore = useSessionMetricsStore()

const showConversionRateImprovement = ref(false)
const showOptimizedVsDefaultConfidenceIntervals = ref(false)

const trafficDistributionData = ref<{
   labels: string[]
   datasets: ChartDataset<"line", (number | Point | null)[]>[]
} | null>(null)
const trafficDistributionGraphRef = ref<null | VNodeRef>(null)
const conversionRateImprovementData = ref<{
   labels: string[]
   datasets: ChartDataset<"line", (number | Point | null)[]>[]
} | null>(null)
const absConversionRateData = ref<{
   labels: string[]
   datasets: ChartDataset<"line", (number | Point | null)[]>[]
} | null>(null)

const sortDatasetsByTraffic = (
   datasets: ChartDataset<"line">[],
   ascending: boolean = false,
) => {
   return [...datasets].sort((a, b) => {
      const trafficA = Number(a.data![a.data!.length - 1])
      const trafficB = Number(b.data![b.data!.length - 1])
      return ascending ? trafficA - trafficB : trafficB - trafficA
   })
}

const filteredTrafficDistributionData = computed(() => {
   if (!trafficDistributionData.value) {
      return null
   }
   if (activeFilter.value.val === "all") {
      return trafficDistributionData.value
   } else if (activeFilter.value.val === "top_10") {
      const top10Datasets = sortDatasetsByTraffic(
         trafficDistributionData.value.datasets,
      ).slice(0, 10)
      return {
         labels: trafficDistributionData.value.labels,
         datasets: top10Datasets,
      }
   } else if (activeFilter.value.val === "bottom_10") {
      const bottom10Datasets = sortDatasetsByTraffic(
         trafficDistributionData.value.datasets,
         true,
      ).slice(-10)
      return {
         labels: trafficDistributionData.value.labels,
         datasets: bottom10Datasets,
      }
   } else {
      logInfo(
         `Invalid traffic filter: ${activeFilter.value.val}, returning all data.`,
      )
      return trafficDistributionData.value
   }
})

const finalTrafficDistributionChartData = computed(() => {
   if (!filteredTrafficDistributionData.value) {
      return null
   }
   let finalData = [...filteredTrafficDistributionData.value.datasets]
   for (let i = 0; i < finalData.length; i++) {
      const dataset = finalData[i]
      dataset.borderColor = getApexColor(i)
      dataset.backgroundColor = getApexColor(i)
   }
   return {
      labels: filteredTrafficDistributionData.value.labels,
      datasets: finalData,
   }
})

const filteredCRImprovementData = computed(() => {
   if (!conversionRateImprovementData.value) {
      return null
   }
   if (activeFilter.value.val == "all") {
      return conversionRateImprovementData.value
   } else if (activeFilter.value.val == "top_10") {
      const orderedDatasetsByConversionRate = [
         ...conversionRateImprovementData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const top10Datasets = orderedDatasetsByConversionRate.slice(0, 10)
      return {
         labels: conversionRateImprovementData.value.labels,
         datasets: top10Datasets,
      }
   } else if (activeFilter.value.val == "bottom_10") {
      const orderedDatasetsByConversionRate = [
         ...conversionRateImprovementData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const bottom10Datasets = orderedDatasetsByConversionRate.slice(-10)
      return {
         labels: conversionRateImprovementData.value.labels,
         datasets: bottom10Datasets,
      }
   } else {
      logInfo("Invalid conversion rate filter, returning all data.")
      return conversionRateImprovementData.value
   }
})

trafficDistributionData.value?.datasets.forEach((dataset) => {
   if (dataset.label == "0") {
      dataset.label = "Default"
   }
})

const getGraphColor = (i: number, label?: string) => {
   if (!filteredTrafficDistributionData.value) {
      return getApexColor(i)
   }

   const match = filteredTrafficDistributionData.value.datasets.find(
      (dataset) => {
         return dataset.label === label
      },
   )

   if (match) {
      return match.backgroundColor
   }

   return undefined
}

const finalCRImprovementData = computed(() => {
   if (!filteredCRImprovementData.value) {
      return null
   }
   let finalData = [...filteredCRImprovementData.value.datasets]
   for (let i = 0; i < finalData.length; i++) {
      const dataset = finalData[i]
      dataset.backgroundColor = getGraphColor(i, dataset.label)
      dataset.borderColor = getGraphColor(i, dataset.label)
   }
   return {
      labels: filteredCRImprovementData.value.labels,
      datasets: finalData,
   }
})

const conversionRateImprovementDatasets = createVariantLiftDatasets()
if (conversionRateImprovementDatasets) {
   conversionRateImprovementData.value = {
      labels: sessionMetricsStore
         .time_series!.map((x) => x.Dt)
         .filter((x) => !!x),
      datasets: conversionRateImprovementDatasets,
   }
}
if (!conversionRateImprovementData.value) {
   throw new Error("No CR Improvement datasets to render")
}

conversionRateImprovementData.value.datasets.forEach((dataset) => {
   if (dataset.label == "0") {
      dataset.label = "Default"
   }
})

const filteredAbsCRData = computed(() => {
   if (!absConversionRateData.value) {
      return null
   }
   if (activeFilter.value.val == "all") {
      return absConversionRateData.value
   } else if (activeFilter.value.val == "top_10") {
      const orderedDatasetsByConversionRate = [
         ...absConversionRateData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const top10Datasets = orderedDatasetsByConversionRate.slice(0, 10)
      return {
         labels: absConversionRateData.value.labels,
         datasets: top10Datasets,
      }
   } else if (activeFilter.value.val == "bottom_10") {
      const orderedDatasetsByConversionRate = [
         ...absConversionRateData.value.datasets,
      ].sort((a, b) => {
         const nextConversionRate = Number(b.data![b.data!.length - 1])
         const lastConversionRate = Number(a.data[a.data.length - 1])
         return nextConversionRate - lastConversionRate
      })
      const bottom10Datasets = orderedDatasetsByConversionRate.slice(-10)
      return {
         labels: absConversionRateData.value.labels,
         datasets: bottom10Datasets,
      }
   } else {
      logInfo("Invalid conversion rate filter, returning all data.")
      return absConversionRateData.value
   }
})

const finalAbsCRData = computed(() => {
   if (!filteredAbsCRData.value) {
      return null
   }
   let finalData = [...filteredAbsCRData.value.datasets]
   for (let i = 0; i < finalData.length; i++) {
      const dataset = finalData[i]
      dataset.backgroundColor = getGraphColor(i, dataset.label)
      dataset.borderColor = getGraphColor(i, dataset.label)
   }
   return {
      labels: filteredAbsCRData.value.labels,
      datasets: finalData,
   }
})

const topFilterOptions: FilterOption[] = [
   {
      label: "All Combinations",
      val: "all",
   },
   {
      label: "Current Top 10",
      val: "top_10",
   },
   {
      label: "Current Bottom 10",
      val: "bottom_10",
   },
]

const activeFilter = ref(topFilterOptions[1])

const onFilterClick = (option: FilterOption) => {
   activeFilter.value = option
}

const updateAbsConversionRateGraph = () => {
   let labels: string[] = sessionMetricsStore
      .time_series!.map((x) => {
         return x.Dt
      })
      .filter((x) => !!x)

   const datasets = createConversionRateByCombinationDatasets()
   if (!datasets) {
      throw new Error("No datasets to render")
   }

   datasets.forEach((dataset) => {
      if (dataset.label == "0") {
         dataset.label = "Default"
      }
   })

   absConversionRateData.value = {
      labels: labels,
      datasets: datasets,
   }
}

const updateConversionRateImprovementGraph = () => {
   let labels: string[] = sessionMetricsStore
      .time_series!.map((x) => {
         return x.Dt
      })
      .filter((x) => !!x)

   const datasets = createVariantLiftDatasets()
   if (!datasets) {
      throw new Error("No datasets to render")
   }
   datasets.forEach((dataset) => {
      if (dataset.label == "0") {
         dataset.label = "Default"
      }
   })
   conversionRateImprovementData.value = {
      labels: labels,
      datasets: datasets,
   }
}

const updateTrafficDistributionGraph = () => {
   let labels: string[] = sessionMetricsStore
      .time_series!.map((x) => {
         return x.Dt
      })
      .filter((x) => !!x)
   const datasets = createVariationServeRateDatasets()
   if (!datasets) {
      throw new Error("No datasets to render")
   }

   // search the datasets array for an object with label: '0' and set it to default
   datasets.forEach((dataset) => {
      if (dataset.label == "0") {
         dataset.label = "Default"
      }
   })

   // Sort the datasets b traffic in the latest data point, descending
   datasets.sort((a, b) => {
      const nextTraffic = Number(b.data![b.data!.length - 1])
      const lastTraffic = Number(a.data[a.data.length - 1])
      return lastTraffic - nextTraffic
   })

   // Tell the graph the order of the datasets
   datasets.forEach((dataset, i) => {
      dataset.order = i
   })

   trafficDistributionData.value = {
      labels: labels,
      datasets: datasets,
   }
}
const updateChartData = () => {
   updateTrafficDistributionGraph()
   updateConversionRateImprovementGraph()
   updateAbsConversionRateGraph()
}
sessionMetricsStore.$subscribe(() => {
   updateChartData()
})

watch(showOptimizedVsDefaultConfidenceIntervals, () => {
   updateChartData()
})

const dashboardStore = useDashboardStore()

const transformedOptimizedVsDefaultData = computed(() => ({
   dt: dashboardStore.time_series?.dt ?? [],
   default_conversion_rate:
      dashboardStore.time_series?.default_conversion_rate ?? [],
   default_std_dev: dashboardStore.time_series?.default_std_dev ?? [],
   ezbot_conversion_rate:
      dashboardStore.time_series?.ezbot_conversion_rate ?? [],
   ezbot_std_dev: dashboardStore.time_series?.ezbot_std_dev ?? [],
}))

await updateChartData()
</script>
<style lang="scss" scoped>
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}
#graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   gap: 1em;
   align-items: center;
}
</style>
