<template>
   <QCard class="serving-rates-card">
      <QCardSection class="bg-primary q-pa-sm">
         <div class="text-h6 text-white">Top Converter Serving Rates</div>
      </QCardSection>
      <QCardSection class="content-section flex justify-center align-center">
         <div class="chart-container">
            <Doughnut
               v-if="!props.loading"
               :data="chartData"
               :options="chartOptions"
            />
            <QSkeleton v-else id="chart-skeleton" type="circle" />
         </div>
      </QCardSection>
   </QCard>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { Doughnut } from "vue-chartjs"
import ChartDataLabels from "chartjs-plugin-datalabels"
import {
   Chart as ChartJS,
   ArcElement,
   Tooltip,
   Legend,
   ChartOptions,
} from "chart.js"
import { TopConverterServingRates } from "@/types/overview"
import { useQuasar } from "quasar"

ChartJS.register(ArcElement, Tooltip, Legend)

const dataLabelsPlugin = {
   ...ChartDataLabels,
   defaults: {
      ...ChartDataLabels.defaults,
      display: false,
   },
}

ChartJS.register(dataLabelsPlugin)

const $q = useQuasar()

// Define props
const props = withDefaults(
   defineProps<{
      data: TopConverterServingRates | null
      loading: boolean
   }>(),
   {
      loading: false,
   },
)

// Chart colors from the ezbot palette
const colors = [
   "#7E00F7",
   "#8D13E9",
   "#9C26DB",
   "#AB39CD",
   "#B83CBF",
   "#C451AF",
]

// Format percentage for display
const formatPercentage = (value: number) => {
   return `${value.toFixed(2)}%`
}

// Prepare chart data
const chartData = computed(() => {
   if (!props.data) {
      return {
         labels: [],
         datasets: [],
      }
   }

   return {
      labels: props.data.segments.map((segment) =>
         segment.combination === "_other_" ? "Other" : segment.combination,
      ),
      datasets: [
         {
            data: props.data.segments.map((segment) => segment.serving_rate),
            backgroundColor: props.data.segments.map((segment) =>
               segment.combination === "_other_"
                  ? "#6e6e6e"
                  : colors[
                       props.data!.segments.indexOf(segment) % colors.length
                    ],
            ),
            borderWidth: 0,
         },
      ],
   }
})

const tooltip = (
   label: string,
   servingRate: number,
   conversionRate: number,
) => {
   let result = []
   result.push(label)
   if (servingRate) {
      result.push(`Serving Rate: ${formatPercentage(servingRate)}`)
   }
   if (conversionRate) {
      result.push(`Conversion Rate: ${formatPercentage(conversionRate * 100)}`)
   }
   return result
}

const chartPadding = computed(() => {
   if ($q.screen.md) {
      return 24
   }
   if ($q.screen.lg || $q.screen.xl) {
      return 32
   }
   return 16
})
// Chart options
const chartOptions: ChartOptions<"doughnut"> = {
   responsive: true,
   maintainAspectRatio: false,
   plugins: {
      legend: {
         display: false,
      },
      datalabels: {
         display: true,
         formatter: (value, context) => {
            if (!props.data) {
               return "-"
            }
            const segment = props.data.segments[context.dataIndex]
            const label =
               segment.combination === "_other_" ? "Other" : segment.combination
            return label + ": " + value.toFixed(2) + "%"
         },
         align: "end",
         backgroundColor: "rgba(0,0,0,0.6)",
         borderRadius: 4,
         padding: 3,
         color: "white",
      },
      tooltip: {
         callbacks: {
            label: (context) => {
               if (!props.data) {
                  return "-"
               }
               const segment = props.data.segments[context.dataIndex]
               const label =
                  segment.combination === "_other_"
                     ? "Other"
                     : segment.combination
               return tooltip(
                  label,
                  segment.serving_rate,
                  segment.conversion_rate,
               )
            },
            title: (context) => {
               if (!props.data) {
                  return "-"
               }
               const segment = props.data.segments[context[0].dataIndex]
               const label =
                  segment.combination === "_other_"
                     ? "Other"
                     : segment.combination
               return "Combination " + label + ":"
            },
         },
      },
   },
   cutout: "70%",
   layout: {
      padding: chartPadding.value,
   },
}
</script>

<style scoped lang="scss">
.content-section {
   align-items: center;
   padding: 12px;
}
.chart-container {
   display: flex;
   align-items: center;
   justify-content: center;
   max-height: 275px;
}

#chart-skeleton {
   height: 250px;
   width: 250px;
}
.serving-rates-card {
   height: 350px;
}
</style>
