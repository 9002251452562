<template>
   <div>
      <div class="chart-container">
         <Line
            v-if="!props.loading"
            id="chart"
            ref="line"
            :options="chartOptions"
            :data="chartData"
            height="200px"
            fullWidth
         />
         <QSkeleton
            v-else
            id="chart-skeleton"
            type="rect"
            height="200px"
            width="100%"
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import {
   ref,
   onMounted,
   onUnmounted,
   computed,
   Ref,
   VNodeRef,
   ComputedRef,
   withDefaults,
} from "vue"
import { Line } from "vue-chartjs"
import { enUS } from "date-fns/locale"
import {
   Chart as ChartJS,
   Tooltip,
   LineElement,
   PointElement,
   TimeScale,
   LinearScale,
   ChartOptions,
   Filler,
   Legend,
   Title,
   ChartData,
   ChartDataset,
   Point,
} from "chart.js"
import "chartjs-adapter-date-fns"
import type { OptVDefaultGraph } from "../types/overview"

ChartJS.register(
   Tooltip,
   LineElement,
   TimeScale,
   LinearScale,
   PointElement,
   Filler,
   Legend,
   Title,
)

const props = withDefaults(
   defineProps<{
      data: OptVDefaultGraph | null
      loading: boolean
      showConfidenceIntervals: boolean
   }>(),
   {
      loading: false,
   },
)

const tooltipEnabled = ref(true)
const line: VNodeRef = ref(null)

const chartData: ComputedRef<ChartData<"line">> = computed(() => {
   if (!props.data) {
      return {
         labels: [],
         datasets: [],
      }
   }
   const datasets: ChartDataset<"line", (number | Point | null)[]>[] = []

   if (!props.data.ezbot_conversion_rate) {
      throw new Error("No ezbot conversion rate data")
   }
   // Add ezbot conversion rate line
   datasets.push({
      label: "ezbot Conv. Rate",
      data: props.data.dt.map((date, index) => {
         return props.data!.ezbot_conversion_rate[index] * 100
      }),
      backgroundColor: "#7E00F7",
      borderColor: "#7E00F7",
      tension: 0.4,
      pointRadius: 0,
   })

   if (!props.data.default_conversion_rate) {
      throw new Error("No default conversion rate data")
   }

   if (!props.data.ezbot_std_dev) {
      throw new Error("No standard deviation data")
   }

   // Add confidence intervals if enabled
   if (props.showConfidenceIntervals) {
      datasets.push({
         label: "ezbot 99% CI",
         data: props.data.dt.map((date, index) => {
            return (
               (props.data!.ezbot_conversion_rate[index] +
                  props.data!.ezbot_std_dev[index] * 2.576) *
               100
            )
         }),
         borderColor: "rgba(128,0,247,0.3)",
         backgroundColor: "rgba(128,0,247,0.3)",
         fill: "+1",
         pointRadius: 0,
         showLine: false,
      })

      datasets.push({
         label: "ezbot 99% CI",
         data: props.data.dt.map((date, index) => {
            return (
               (props.data!.ezbot_conversion_rate[index] -
                  props.data!.ezbot_std_dev[index] * 2.576) *
               100
            )
         }),
         backgroundColor: "rgba(128,0,247,0.3)",
         borderColor: "rgba(128,0,247,0.3)",
         fill: -1,
         pointRadius: 0,
         showLine: false,
      })
   }

   if (!props.data.default_conversion_rate) {
      throw new Error("No default conversion rate data")
   }
   // Add default conversion rate line
   datasets.push({
      label: "Default Conv. Rate",
      data: props.data.dt.map((date, index) => {
         return props.data!.default_conversion_rate[index] * 100
      }),
      backgroundColor: "#6e6e6e",
      borderColor: "#6e6e6e",
      pointRadius: 0,
   })

   if (!props.data.default_std_dev) {
      throw new Error("No standard deviation data")
   }

   // Add default confidence intervals if enabled
   if (props.showConfidenceIntervals) {
      datasets.push({
         label: "Default 99% CI",
         data: props.data.dt.map((date, index) => {
            return (
               (props.data!.default_conversion_rate[index] +
                  props.data!.default_std_dev[index] * 2.576) *
               100
            )
         }),
         borderColor: "rgba(110,110,110,0.1)",
         backgroundColor: "rgba(110,110,110,0.1)",
         fill: "+1",
         pointRadius: 0,
         showLine: false,
      })

      datasets.push({
         label: "Default 99% CI",
         data: props.data.dt.map((date, index) => {
            return (
               (props.data!.default_conversion_rate[index] -
                  props.data!.default_std_dev[index] * 2.576) *
               100
            )
         }),
         fill: "-1",
         backgroundColor: "rgba(110,110,110,0.1)",
         borderColor: "rgba(110,110,110,0.1)",
         pointRadius: 0,
         showLine: false,
      })
   }
   let labels: string[] = props.data.dt
   return { labels: labels, datasets: datasets }
})

const chartOptions: Ref<ChartOptions<"line">> = computed(() => ({
   responsive: true,
   interaction: {
      mode: "index",
      intersect: false,
   },
   elements: {
      line: {
         tension: 0.4,
      },
   },
   scales: {
      y: {
         ticks: {
            // Include a percentage sign in the ticks
            callback: function (tickValue: string | number) {
               return Math.abs(Number(tickValue)).toFixed(2) + "%"
            },
         },
         position: "right",
         grid: {
            display: true,
            lineWidth: (context) => {
               const length = context.scale.ticks.length
               if (context.index === 0 || context.index === length - 1) {
                  return 0
               }
               return 1
            },
         },
      },
      x: {
         display: false,
         grid: {
            display: false,
         },
         type: "time",
         time: {
            unit: "day",
            // round: "day",
            // tooltipFormat: "MMM d HH:00",
         },
         adapters: {
            date: {
               locale: enUS,
            },
         },
         ticks: {
            source: "auto",
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
         },
      },
   },
   plugins: {
      datalabels: {
         display: false,
      },
      filler: {
         drawTime: "beforeDatasetsDraw",
         propagate: true,
      },
      title: {
         display: true,
         text: "ezbot vs Default Conversion Rates",
         font: {
            size: 18,
         },
      },
      legend: {
         display: true,
         position: "left",
         maxWidth: 150,
         labels: {
            boxWidth: 10,
         },
         onClick: () => {
            // Prevent the default action
            return
         },
      },
      tooltip: {
         enabled: tooltipEnabled.value,
         padding: 10,
         caretPadding: 10,
         itemSort: (a, b) => {
            const aValue = a.parsed.y
            const bValue = b.parsed.y
            return bValue - aValue
         },
         callbacks: {
            title: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  timeZoneName: "short",
               }
               return date.toLocaleString(undefined, options)
            },
            label: function (context) {
               var label = context.dataset.label || ""

               if (label) {
                  label += ": "
               }
               if (context.parsed.y !== null) {
                  label += Math.abs(context.parsed.y).toFixed(2) + "%"
               }
               return label
            },
            beforeBody: () => {
               return "Conversion Rates:"
            },
         },
      },
   },
}))

const handleResize = () => {
   if (line.value) {
      line.value.chart.resize()
   }
}

onMounted(() => {
   window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
   window.removeEventListener("resize", handleResize)
})
</script>

<style lang="scss" scoped>
#chart {
   position: relative;
   height: auto;
   width: 100%;
}
.chart-container {
   position: relative;
   height: 100%;
   width: 100%;
}
</style>
