<template>
   <QCard class="graph-card">
      <QCardSection class="bg-primary">
         <div class="text-h5 text-white">Performance</div>
      </QCardSection>
      <QCardSection>
         <div id="controls">
            <QToggle
               size="sm"
               v-model="showOptimizedVsDefaultConfidenceIntervals"
               :val="showOptimizedVsDefaultConfidenceIntervals"
               label="Show 99% Confidence Intervals"
            />
         </div>
         <OptimizedVsDefaultGraph
            :show-confidence-intervals="
               showOptimizedVsDefaultConfidenceIntervals
            "
            :data="graphData"
         />
         <NTileTrafficDistributionGraph />
         <NTileVsDefaultConversionRateGraph
            id="conversion-graph"
            class="graph"
            :showRelative="showRelative"
         ></NTileVsDefaultConversionRateGraph>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { ref, computed } from "vue"
import { useDashboardStore } from "../../../stores/dashboardStore"
import OptimizedVsDefaultGraph from "../../OptimizedVsDefaultGraph.vue"
import NTileTrafficDistributionGraph from "../../NTileTrafficDistributionGraph.vue"
import NTileVsDefaultConversionRateGraph from "../../conversion-rate-graph-v2/NTileVsDefaultConversionRateGraph.vue"

const dashboardStore = useDashboardStore()
const showOptimizedVsDefaultConfidenceIntervals = ref(false)
const showRelative = ref(false)

const graphData = computed(() => ({
   dt: dashboardStore.time_series?.dt ?? [],
   default_conversion_rate:
      dashboardStore.time_series?.default_conversion_rate ?? [],
   default_std_dev: dashboardStore.time_series?.default_std_dev ?? [],
   ezbot_conversion_rate:
      dashboardStore.time_series?.ezbot_conversion_rate ?? [],
   ezbot_std_dev: dashboardStore.time_series?.ezbot_std_dev ?? [],
}))
</script>
<style scoped lang="scss">
#optimized-vs-default-graph-controls {
   display: flex;
   justify-content: start;
   margin-bottom: 10px;
}
#controls {
   display: flex;
   gap: 1em;
}
</style>
