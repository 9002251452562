<template>
   <DashboardHeader
      v-if="dashboardStore.hasVariants && !dashboardStore.loading"
   />
   <div class="bg-white q-pa-md" v-else>
      <InstallInstructions2 />
   </div>
   <div id="main" class="q-pa-md">
      <InstallInstructions
         v-if="!dashboardStore.hasVariants && !dashboardStore.loading"
      />
      <template v-else>
         <UnifiedGraphCard />
         <VariableSnapshotCard />
         <CombinationsTableV2 />
      </template>
   </div>
</template>
<script setup lang="ts">
import InstallInstructions from "../../components/data-explorer/InstallInstructions.vue"
import InstallInstructions2 from "../../components/data-explorer/InstallInstructions2.vue"
import { DashboardHeader } from "@components/index"
import { collectError, logInfo } from "@utils/index"
import { useDashboardStore } from "@stores/dashboardStore"
import UnifiedGraphCard from "./graphs/UnifiedGraphCard.vue"
import CombinationsTableV2 from "@components/combinations-table-v2/CombinationsTableV2.vue"
import VariableSnapshotCard from "@components/variable-snapshot/VariableSnapshotCard.vue"
import { onMounted, watch } from "vue"
import { useProjectStore } from "@stores/projectStore"
import { date } from "quasar"
const dashboardStore = useDashboardStore()
const { formatDate, subtractFromDate } = date

const projectStore = useProjectStore()

try {
   if (!dashboardStore.query_start_date || !dashboardStore.query_end_date) {
      logInfo("No summary stats found. Fetching dashboard data")
      const startDateString = formatDate(
         subtractFromDate(Date.now(), { days: 30 }),
         "YYYY/MM/DD",
      )

      const endDateString = formatDate(Date.now(), "YYYY/MM/DD")
      await dashboardStore.getDashboard(startDateString, endDateString)
   } else {
      await dashboardStore.updateDashboard([])
   }
} catch (error) {
   collectError(error as Error)
}
onMounted(() => {
   projectStore.getVariablesByProjectId()
})
watch(
   () => projectStore.projectId,
   async () => {
      if (projectStore.projectId) {
         projectStore.getVariablesByProjectId()
         dashboardStore.updateDashboard([])
      }
   },
)
</script>
<style scoped lang="scss">
#main {
   min-height: 600px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}
</style>
