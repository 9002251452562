<template>
   <div
      id="header"
      class="q-pa-sm bg-white"
      style="
         position: sticky;
         top: 74px;
         z-index: 999;
         border-bottom: 1px solid #ccc;
         box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      "
   >
      <h1
         id="dashboard-title"
         class="text-h3 text-left text-bold q-mb-none q-mt-none"
      >
         Overview
      </h1>
      <div
         id="datepicker-container"
         class="row no-wrap q-gutter-md items-center"
      >
         <QSelect
            class="col-grow"
            style="min-width: 200px"
            label="UTM Source"
            v-model="selectedUtmSource"
            :options="utmSourceOptions"
            @filter="onUtmSourceFilter"
            use-input
            hide-selected
            fill-input
            @popup-show="loadUtmSources"
         />
         <DatePickerSelector
            :start-date="startDate"
            :end-date="endDate"
            @update:dates="handleDateChange"
         />
      </div>
   </div>
   <div id="main" class="q-pa-md bg-white q-pt-lg">
      <div class="row q-col-gutter-md">
         <div class="col-12 col-lg-4">
            <ConversionLiftCard
               :loading="overviewLoading"
               :data="overviewStore.data?.opt_v_default_card!"
            />
         </div>
         <div class="col-12 col-md-6 col-lg-4">
            <AdditionalConversionsCard
               :loading="overviewLoading"
               :data="overviewStore.data?.addl_conversions_card!"
            />
         </div>
         <div class="col-12 col-md-6 col-lg-4">
            <TopConverterServingRatesCard
               :loading="overviewLoading"
               :data="overviewStore.data?.top_converter_serving_rates_card!"
            />
         </div>
      </div>
      <QCard class="graph-card">
         <QCardSection class="bg-primary q-pa-sm">
            <div class="text-h6 text-white">
               ezbot vs Default Conversion Rates
            </div>
         </QCardSection>
         <QCardSection>
            <div id="optimized-vs-default-graph-controls">
               <QToggle
                  size="sm"
                  v-model="showOptimizedVsDefaultConfidenceIntervals"
                  :val="showOptimizedVsDefaultConfidenceIntervals"
                  label="Show 99% Confidence Intervals"
               />
            </div>
            <OptimizedVsDefaultGraph
               :show-confidence-intervals="
                  showOptimizedVsDefaultConfidenceIntervals
               "
               :loading="overviewLoading"
               :data="graphData!"
            />
         </QCardSection>
      </QCard>
   </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue"
import { useOverviewStore } from "@stores/overviewStore"
import OptimizedVsDefaultGraph from "@components/OptimizedVsDefaultGraph.vue"
import DatePickerSelector from "@components/DatePickerSelector.vue"
import TopConverterServingRatesCard from "@components/TopConverterServingRatesCard.vue"
import ConversionLiftCard from "@components/ConversionLiftCard.vue"
import AdditionalConversionsCard from "../components/AdditionalConversionsCard.vue"

const overviewStore = useOverviewStore()
const showOptimizedVsDefaultConfidenceIntervals = ref(false)
const overviewLoading = ref(false)
const selectedUtmSource = ref<string | null>(null)
const utmSourceOptions = ref<string[]>([])

const onUtmSourceFilter = (
   val: string,
   // eslint-disable-next-line no-unused-vars
   update: (callback: () => void) => void,
) => {
   if (val === "") {
      overviewStore
         .loadUtmSources(startDate.value, endDate.value)
         .then((val) => {
            update(() => {
               utmSourceOptions.value = val
            })
         })
      return
   }

   overviewStore
      .loadUtmSourcesAc(startDate.value, endDate.value, val)
      .then((val) => {
         update(() => {
            utmSourceOptions.value = val
         })
      })
   return
}

// Initialize dates if not set
const startDate = ref(
   overviewStore.start_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() - 30)
         return Math.floor(date.getTime())
      })(),
)

const endDate = ref(
   overviewStore.end_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() + 1)
         return Math.floor(date.getTime())
      })(),
)

onMounted(async () => {
   overviewLoading.value = true
   await overviewStore.fetchOverview(startDate.value, endDate.value)
   overviewLoading.value = false
})

const graphData = computed(() => {
   if (!overviewStore.data) {
      return {
         dt: [],
         default_conversion_rate: [],
         default_std_dev: [],
         ezbot_conversion_rate: [],
         ezbot_std_dev: [],
      }
   }
   return overviewStore.data.opt_v_default_graph_card
})

const handleDateChange = async (newStartDate: number, newEndDate: number) => {
   overviewLoading.value = true
   startDate.value = newStartDate
   endDate.value = newEndDate
   await overviewStore.fetchOverview(
      newStartDate,
      newEndDate,
      selectedUtmSource.value,
   )
   overviewLoading.value = false
}

watch(selectedUtmSource, async (newValue) => {
   await overviewStore.fetchOverview(startDate.value, endDate.value, newValue)
})

const loadUtmSources = async () => {
   return overviewStore.loadUtmSources(startDate.value, endDate.value)
}
</script>
<style scoped lang="scss">
#header {
   display: flex;
   justify-content: space-between;
   align-content: center;
   flex-direction: row;
   gap: 1em;
   padding: 20px;
   h1 {
      text-align: center;
      font-size: 2rem;
   }
}
#main {
   min-height: 600px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}
#datepicker-container {
   flex: 1;
   min-width: 0;
   max-width: 400px;
   display: flex;
   align-items: center;
}

@media (max-width: 767px) {
   #datepicker-container {
      flex-direction: column;
      align-items: stretch;
   }

   #header {
      h1 {
         font-size: 2rem;
      }
   }
}
@media (min-width: 768px) {
   #header {
      h1 {
         font-size: 3rem;
      }
   }
}
</style>
