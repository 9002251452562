import { createTypedChart } from "vue-chartjs"
import { BarWithErrorBarsController } from "chartjs-chart-error-bars"

// The first argument is the chart-id, the second the chart type, third is the custom controller
export const BarWithErrorBars = createTypedChart(
   "barWithErrorBars",
   BarWithErrorBarsController,
)

// 5. Extend the CustomLine Component just like you do with the default vue-chartjs charts.
