import { defineStore } from "pinia"
import { adminBackendClient } from "@/services/adminBackendClient"
import { useProjectStore } from "./projectStore"
import { Overview } from "@/types/overview"
import { collectError } from "@utils/errors"
import { toast } from "vue3-toastify"
import overview from "@/mocks/overview"

interface OverviewState {
   error: string | null
   start_date: number | null
   end_date: number | null
   data: Overview | null
}

export const useOverviewStore = defineStore("overviewStore", {
   state: (): OverviewState => ({
      error: null,
      start_date: null,
      end_date: null,
      data: null,
   }),

   getters: {
      hasData: (state) => state.data !== null,
      dateRange: (state) => ({
         start: state.start_date,
         end: state.end_date,
      }),
   },

   actions: {
      async callForOverview(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         const params: Record<string, unknown> = {
            start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
            end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
         }

         if (utmSource !== null && utmSource != "") {
            params.utm_source = utmSource
         }

         return await adminBackendClient.get<Overview>(
            `/v2/projects/${projectStore.projectId}/overview`,
            { params },
         )
      },
      async fetchOverview(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         if (!projectStore.projectId) {
            throw new Error("No active project selected")
         }

         this.error = null

         try {
            let response = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               // simulate network delay
               await new Promise((resolve) =>
                  setTimeout(resolve, Math.random() * 750 + 250),
               )
               response = { data: overview }
            } else {
               response = await this.callForOverview(
                  startDate,
                  endDate,
                  utmSource,
               )
            }

            this.data = response.data
            this.start_date = startDate
            this.end_date = endDate
         } catch (error) {
            // Collect error and retry once (temporary fix)
            await collectError(error as Error)
            try {
               const response = await this.callForOverview(
                  startDate,
                  endDate,
                  utmSource,
               )
               this.data = response.data
               this.start_date = startDate
               this.end_date = endDate
            } catch (error) {
               const message = "Failed to fetch overview data"
               this.error = message
               toast.error(message)
            }
            return null
         }
      },

      async loadUtmSources(startDate: number, endDate: number) {
         try {
            let response = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: ["google", "facebook", "instagram", "bing"] }
            } else {
               const projectStore = useProjectStore()
               if (!projectStore.projectId) {
                  throw new Error("No active project selected")
               }
               response = await adminBackendClient.get(
                  `/v2/projects/${projectStore.projectId}/field-values`,
                  {
                     params: {
                        field_name: "utm_source",
                        start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
                        end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
                     },
                  },
               )
            }
            // utmSourceOptions.value = response.data
            return response.data as string[]
         } catch (error) {
            console.error("Failed to load UTM sources:", error)
            return [] as string[]
         }
      },

      async loadUtmSourcesAc(
         startDate: number,
         endDate: number,
         prefix: string,
      ) {
         try {
            let response = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = {
                  data: ["google", "facebook", "instagram", "bing"].filter(
                     (v) => v.toLocaleLowerCase().indexOf(prefix) > -1,
                  ),
               }
            } else {
               const projectStore = useProjectStore()
               if (!projectStore.projectId) {
                  throw new Error("No active project selected")
               }
               response = await adminBackendClient.get(
                  `/v2/projects/${projectStore.projectId}/field-suggestions`,
                  {
                     params: {
                        field_name: "utm_source",
                        start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
                        end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
                        prefix: prefix,
                     },
                  },
               )
            }
            // utmSourceOptions.value = response.data
            return response.data as string[]
         } catch (error) {
            console.error("Failed to load UTM sources:", error)
            return [] as string[]
         }
      },

      clearState() {
         this.error = null
         this.start_date = null
         this.end_date = null
         this.data = null
      },
   },

   persist: true,
})
