<template>
   <QBtn color="primary" :outline="datePickerOpened" icon-right="mdi-calendar">
      <span style="margin-right: 15px">{{ dateRangeStringToDisplay }}</span>
      <QPopupProxy
         v-model="popUpOpen"
         @before-show="datePickerOpened = true"
         @before-hide="datePickerOpened = false"
      >
         <QDate v-model="dateRange" range :options="dateOptions">
            <div class="row items-center justify-end q-gutter-sm">
               <QBtn label="Cancel" color="primary" flat v-close-popup />
               <QBtn
                  label="OK"
                  color="primary"
                  flat
                  v-close-popup
                  @click="save"
               />
            </div>
         </QDate>
      </QPopupProxy>
   </QBtn>
</template>
<script setup lang="ts">
import { QDate, QPopupProxy, QBtn } from "quasar"
import { computed, ref, watch } from "vue"
import { date } from "quasar"
const { formatDate } = date

const props = defineProps<{
   startDate: number
   endDate: number
}>()

const emit = defineEmits<{
   // eslint-disable-next-line no-unused-vars
   (e: "update:dates", startDate: number, endDate: number): void
}>()

const dateOptions = (dateString: string) => {
   const dateToCheck = new Date(dateString)
   const startDate = new Date()
   startDate.setFullYear(startDate.getFullYear() - 1)
   return dateToCheck >= startDate
}

const popUpOpen = ref(false)
const datePickerOpened = ref(false)

const formattedStartDate = computed(() => {
   return formatDate(props.startDate, "YYYY/MM/DD")
})

const formattedEndDate = computed(() => {
   return formatDate(props.endDate, "YYYY/MM/DD")
})

const dateRange = ref({
   from: formattedStartDate.value,
   to: formattedEndDate.value,
})

// Keep dateRange in sync with props
watch(
   () => [props.startDate, props.endDate],
   ([newStartDate, newEndDate]) => {
      dateRange.value = {
         from: formatDate(newStartDate, "YYYY/MM/DD"),
         to: formatDate(newEndDate, "YYYY/MM/DD"),
      }
   },
)

const dateRangeString = computed(() => {
   const { from, to } = dateRange.value || {}
   return from && to
      ? `${formatDate(from, "MM/DD/YYYY")} to ${formatDate(to, "MM/DD/YYYY")}`
      : null
})

const dateRangeStringToDisplay = ref(dateRangeString.value)

const updateDateRangeString = () => {
   dateRangeStringToDisplay.value = dateRangeString.value
}

watch(dateRange, () => {
   if (dateRange.value !== null) {
      updateDateRangeString()
   }
})

const save = () => {
   if (!dateRange.value?.from || !dateRange.value?.to) {
      dateRange.value = {
         from: formattedStartDate.value,
         to: formattedEndDate.value,
      }
      return
   }

   const startTimestamp = Math.floor(new Date(dateRange.value.from).getTime())
   const endTimestamp = Math.floor(new Date(dateRange.value.to).getTime())
   emit("update:dates", startTimestamp, endTimestamp)
}
</script>
