<template>
   <div>
      <QCard id="install-card" :flat="props.flat">
         <QCardSection v-if="props.header" class="bg-primary">
            <h2 id="install-ezbot" class="text-h5 text-white">Install ezbot</h2>
         </QCardSection>
         <QCardSection>
            <QTabs
               v-model="tab"
               dense
               class="text-grey"
               active-color="primary"
               indicator-color="primary"
               align="justify"
               narrow-indicator
            >
               <QTab name="html" label="Javascript Snippet (HTML)" />
               <QTab name="js" label="Javascript SDK" />
               <!-- <QTab name="other" label="Other" /> -->
            </QTabs>

            <QSeparator />

            <QTabPanels v-model="tab" animated>
               <QTabPanel name="html">
                  <p>
                     For ezbot to work, you'll need to install the Web Tracker
                     via Javascript Snippet or SDK. The Javascript Snippet is
                     the easiest way to get started, but the SDK provides more
                     control and customization. After the Web Tracker is
                     installed, your application will begin sending page views,
                     button clicks, and other data to ezbot. For more
                     information, check out
                     <a
                        href="https://docs.ezbot.ai/get-started/install/"
                        target="_blank"
                        >our installation guide.</a
                     >
                  </p>
                  <VCodeBlock
                     :code="htmlCode"
                     highlightjs
                     label="To begin tracking, add this to your <head> tag:"
                     lang="html"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <p>
                     If you want to optimize for a user visiting a page, like a
                     checkout confirmation page,
                     <RouterLink :to="{ name: 'Rewards' }"
                        >configure a Page View Event
                     </RouterLink>
                     and you're done!
                  </p>
                  <VCodeBlock
                     :code="htmlRewardCode"
                     highlightjs
                     label="(Optional) If you want ezbot to optimize for any other user action,
                     trigger a reward event from your code:"
                     lang="javascript"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <p>
                     See the our
                     <a href="https://docs.ezbot.ai/get-started/rewards/">
                        Rewards doc</a
                     >
                     for more information and an example reward.
                  </p>
               </QTabPanel>

               <QTabPanel name="js">
                  <p>
                     For ezbot to work, you'll need to install the Web Tracker
                     via Javascript Snippet or SDK. The Javascript Snippet is
                     the easiest way to get started, but the SDK provides more
                     control and customization. After the Web Tracker is
                     installed, your application will begin sending page views,
                     button clicks, and other data to ezbot. For more
                     information, check out
                     <a
                        href="https://docs.ezbot.ai/get-started/install/"
                        target="_blank"
                        >our installation guide.</a
                     >
                  </p>
                  <VCodeBlock
                     :code="jsInstallCode"
                     highlightjs
                     label="Install the JS SDK via NPM or Yarn:"
                     lang="bash"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <VCodeBlock
                     :code="jsInitCode"
                     highlightjs
                     label="Initialize the sdk client-side, somewhere where it will run on every page, such as in a layout component:"
                     lang="javascript"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <VCodeBlock
                     :code="jsTrackingEventCode"
                     highlightjs
                     label="From any client-side code, track page views, reward events, and more:"
                     lang="javascript"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <p>
                     (Optional) If you want to optimize for a user visiting a
                     page, like a checkout confirmation page,
                     <RouterLink :to="{ name: 'Rewards' }"
                        >you can configure a Page View Event
                     </RouterLink>
                     &nbsp;instead of calling trackRewardEvent.
                  </p>
                  <p>
                     See the our
                     <a href="https://docs.ezbot.ai/get-started/rewards/">
                        Rewards doc</a
                     >
                     for more information and an example reward.
                  </p>
               </QTabPanel>
               <!-- <QTabPanel name="other">
                  <p>
                     For ezbot to work, you'll need to install the Web Tracker
                     via Javascript Snippet or SDK. The Javascript Snippet is
                     the easiest way to get started, but the SDK provides more
                     control and customization. After the Web Tracker is
                     installed, your application will begin sending page views,
                     button clicks, and other data to ezbot.
                  </p>
                  <p>
                     If you want to use ezbot predictions or send ezbot reward
                     events from anywhere in your code, in any language, follow
                     the instructions below.
                  </p>
                  <VCodeBlock
                     :code="rawPredictionsCode"
                     highlightjs
                     label="Request predictions from ezbot:"
                     lang="http"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
                  <VCodeBlock
                     :code="predictionsResponse"
                     highlightjs
                     label="Then, use the predictions to make changes to your site:"
                     lang="json"
                     theme="github-dark-dimmed"
                     copy-button
                     :persistent-copy-button="true"
                  />
               </QTabPanel> -->
            </QTabPanels>
         </QCardSection>
      </QCard>
   </div>
</template>
<script lang="ts" setup>
import { ref } from "vue"
import { useProjectStore } from "@stores/projectStore"
import { VCodeBlock } from "@wdns/vue-code-block"
const props = defineProps({
   header: {
      type: Boolean,
      required: false,
      default: true,
   },
   flat: {
      type: Boolean,
      required: false,
      default: false,
   },
})

const projectStore = useProjectStore()
const tab = ref<string>("html")

const htmlRewardCode = ref<string>("")
const htmlCode = ref<string>("")
const jsInstallCode = ref<string>("")
const jsInitCode = ref<string>("")
const jsTrackingEventCode = ref<string>("")
const rawPredictionsCode = ref<string>("")
const predictionsResponse = ref<string>("")

htmlCode.value = `
<script src="https://cdn.ezbot.ai/web-snippets/ezbot.min.js"><\/script>
<script async>
   await ezbot.initEzbot(${projectStore.projectId});
   ezbot.startActivityTracking({
         minimumVisitLength: 10,
         heartbeatDelay: 10,
   });
   ezbot.trackPageView();
   ezbot.makeVisualChanges();
<\/script>`

htmlRewardCode.value = `
// When user completes a rewardable action, like clicking a button:
if (condition) { // replace with your condition
   ezbot.trackRewardEvent({
      key: "name_of_your_reward",
      reward: 1,
      rewardUnits: "count",
   });
}
`

jsInstallCode.value = `
npm install @ezbot-ai/javascript-sdk
yarn add @ezbot-ai/javascript-sdk`
jsInitCode.value = `
import { initEzbot, startActivityTracking } from "@ezbot-ai/javascript-sdk";
await initEzbot(${projectStore.projectId});
startActivityTracking({
  minimumVisitLength: 10,
  heartbeatDelay: 10,
});
`
jsTrackingEventCode.value = `
import { trackPageView, trackRewardEvent, makeVisualChanges } from "@ezbot-ai/javascript-sdk";
// After route change or page load
trackPageView();
makeVisualChanges(); // Optional: If you want to use our Visual Editor

// When user completes a rewardable action
trackRewardEvent({
  key: "name_of_your_reward",
  reward: 1,
  rewardUnits: "count",
});
`

rawPredictionsCode.value = `
GET https://api.ezbot.ai/predict?projectId=${projectStore.projectId}&sessionId={{sessionID}}
`
predictionsResponse.value = `
{
    "holdback": false,
    "predictions": [
        {
            "key": "84086170-519a-4762-9422-0c9d7fadf635",
            "type": "visual",
            "version": "0.2",
            "value": "More conversions, less effort",
            "config": {
                "selector": ".row #hero-headline",
                "action": "setText",
                "attribute": null
            }
        },
        {
            "key": "hero_cta",
            "type": "visual",
            "version": "0.2",
            "value": "Explore Benefits",
            "config": {
                "selector": "#hero-cta",
                "action": "setText",
                "attribute": null
            }
        }
    ]
}
`
</script>
<style lang="scss">
pre {
   text-align: left;
}
#install-card {
   h2 {
      margin: 0 auto;
   }
   p {
      text-align: left;
   }
}
</style>
